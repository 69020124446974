body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  background: #000 url("./assets/images/body_bg.png") repeat;
  color: #ffffff;
  overflow-x: hidden;
}

a {
  color: #0056ff;
  text-decoration: none;
}

a:hover {
  color: #0056ff;
  text-decoration: underline;
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #0056ff;
}

img.brand_logo {
  width: 160px;
}

.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #000;
  margin-top: -100px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
}

.navbar-sticky--on {
  margin-top: 0;
}

.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.main_navbar {
  z-index: 10;
  padding: 15px 0;
}

.main_navbar .primary_btn span {
  background: linear-gradient(
    167deg,
    rgba(255, 0, 8, 1) 0%,
    rgba(0, 86, 255, 1) 100%
  );
}

.main_navbar .navbar-brand {
  display: flex;
}

.navbar_right {
  display: flex;
  align-items: center;
  gap: 12px;
}

.address_btn span {
  width: 180px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: block;
}

.dropdown-toggle.primary_btn::after {
  position: absolute;
  right: 15px;
}

.dropdown-menu {
  background: rgb(37 37 37 / 100%);
  mix-blend-mode: plus-lighter;
  padding: 0;
  overflow: hidden;
}

.dropdown-menu li a {
  color: #fff;
  font-size: 15px;
  text-decoration: none !important;
}

.dropdown-menu li a:hover {
  background: linear-gradient(
    167deg,
    rgba(255, 0, 8, 1) 0%,
    rgba(0, 86, 255, 1) 100%
  );
  color: #fff;
}

.page_header {
  background: url("./assets/images/banner_bg.jpg") no-repeat center;
  background-size: cover;
  height: 100vh;
  margin-top: -82px;
  position: relative;
  z-index: 1;
}

.page_header::before {
  content: "";
  border-radius: 100px;
  background: conic-gradient(
    from 90deg at 50.09% 53.73%,
    #ff0008 126.90277576446533deg,
    #0056ff 360deg
  );
  filter: blur(75px);
  width: 543px;
  height: 509px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 20%;
  z-index: -1;
}

.banner_content {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  gap: 40px;
}

.banner_content h5 {
  font-size: 26px;
  font-weight: 500;
  line-height: 46px;
  letter-spacing: -1.04px;
  margin-bottom: 0;
}

.primary_btn {
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(255, 0, 8, 1) 0%,
    rgba(0, 86, 255, 1) 100%
  );
  border: 0;
  backdrop-filter: blur(2.5px);
  border-radius: 30px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #ffffff !important;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.72px;
  justify-content: center;
  line-height: normal;
  max-width: 100%;
  min-width: 140px;
  padding: 3px;
  text-decoration: none;
  user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none !important;
}

.primary_btn:active,
.primary_btn:hover {
  outline: 0;
}

.primary_btn span {
  background-color: #000;
  padding: 12px 24px;
  border-radius: 30px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}

.primary_btn:hover span {
  background: none;
}

.primary_btn:active {
  transform: scale(0.9);
}

section {
  padding: 50px 0;
  position: relative;
  z-index: 1;
}

.main_title {
  font-size: 96px;
  font-weight: 600;
  line-height: 96px;
  letter-spacing: -5.76px;
  margin-bottom: 40px;
}

.small_main_title {
  font-size: 40px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.6px;
}

.para {
  font-size: 18px;
  line-height: 32px;
}

.about_section h5 {
  color: #9b9999;
  font-size: 24px;
  font-family: 'Salsa', cursive;
  font-weight: 400;
  line-height: 32px;
  margin-top: 40px;
  margin-left: 50px;
}

.about_section {
  padding: 100px 0;
}

.about_section::before {
  content: "";
  background: url("./assets/images/about_before.png") no-repeat center left;
  width: 759px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.about_section::after {
  content: "";
  background: url("./assets/images/about_after.png") no-repeat center right;
  width: 759px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.community_section {
  background: url("./assets/images/img_01.png") no-repeat center;
  padding: 200px 0;
  z-index: 2;
}

.community_section::before {
  content: "";
  background: url("./assets/images/bg_01.png") repeat center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.community_section::after {
  content: "";
  background: url("./assets/images/img_01.png") repeat center;
  width: 600px;
  height: 596px;
  position: absolute;
  left: 0;
  right: 0;
  top: 10%;
  margin: 0 auto;
  z-index: -1;
}

.community_section_inner {
  position: relative;
  height: 100%;
}

.community_section_inner .nav-pills {
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: space-between;
}

.community_section_inner .tab-content {
  max-width: 40%;
  margin-left: auto;
}

.community_section_inner .nav-fill .nav-item {
  flex: unset;
}

.community_section_inner .nav-fill .nav-item .nav-link {
  border-radius: 100px;
  background: #000;
  padding: 15px 30px 15px 15px;
  color: #616161;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: -1.6px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.community_section_inner .nav-fill .nav-item .nav-link.active,
.community_section_inner .nav-fill .nav-item .nav-link:hover {
  background: #0056ff;
  color: #fff;
}

.community_section_inner .nav-fill .nav-item .nav-link.active svg circle,
.community_section_inner .nav-fill .nav-item .nav-link:hover svg circle {
  fill: #fff;
}

.button__icon-wrapper {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  position: relative;
  color: #000;
  background-color: #fff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.community_section_inner .nav-fill .nav-item:hover .button__icon-wrapper {
  color: #000;
}

.button__icon-svg--copy {
  position: absolute;
  transform: translate(-150%, 150%);
}

.community_section_inner
  .nav-fill
  .nav-item
  .nav-link:hover
  .button__icon-svg:first-child {
  transition: transform 0.3s ease-in-out;
  transform: translate(150%, -150%);
}

.community_section_inner
  .nav-fill
  .nav-item
  .nav-link:hover
  .button__icon-svg--copy {
  transition: transform 0.3s ease-in-out 0.1s;
  transform: translate(0);
  color: #0056ff;
}

.community_section_inner .nav-fill .nav-item .nav-link:hover {
  background: #0056ff;
}

.activity_section {
  padding: 100px 0;
}

.activity_section .main_title {
  color: #ff0008;
  text-align: center;
  margin-bottom: 50px;
}

.activity_section .small_main_title {
  text-align: center;
  margin-top: 50px;
}

.activity_slider_single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 100px;
  background: rgba(14, 14, 14, 0.5);
  border: 3px solid rgba(14, 14, 14, 0.5);
  padding: 20px;
  margin: 10px;
}

.activity_section
  .slick-active.slick-center.slick-current
  .activity_slider_single {
  border: 3px solid #0056ff;
}

.activity_details {
  display: flex;
  align-items: center;
  gap: 20px;
}

.activity_details img {
  width: 62px;
}

.activity_details p {
  color: #0056ff;
  margin-bottom: 0;
}

.activity_details h5 {
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.96px;
  margin-bottom: 0;
}

.activity_time {
  display: flex;
  align-items: center;
  gap: 10px;
}

.activity_time p {
  color: #616161;
  margin-bottom: 0;
}

.activity_section .slick-slider {
  margin: 50px 0 120px;
}

.activity_section .slick-active.slick-center.slick-current .activity_details p {
  color: #fff;
}

.activity_section .slick-active.slick-center.slick-current .activity_time p {
  color: #0056ff;
}

.activity_section
  .slick-active.slick-center.slick-current
  .activity_time
  svg
  path {
  fill: #0056ff;
}

.activity_details > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.custom_slider .slick-prev,
.custom_slider .slick-next {
  background: #0e0e0e !important;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  z-index: 1;
  display: flex !important;
  align-items: center;
  justify-content: center;
  top: unset;
  bottom: -150px;
}

.custom_slider .slick-next::before,
.custom_slider .slick-prev::before {
  content: "";
  width: 29px;
  height: 22px;
  filter: brightness(0) invert(0.3);
}

.custom_slider .slick-prev::before {
  background: url("./assets/images/prev_arrow.png") no-repeat;
}

.custom_slider .slick-next::before {
  background: url("./assets/images/next_arrow.png") no-repeat;
}

.custom_slider .slick-prev:hover::before,
.custom_slider .slick-next:hover::before {
  filter: unset;
}

.custom_slider .slick-prev {
  left: 44%;
}

.custom_slider .slick-next {
  right: 44%;
}

.activity_section::before {
  content: "";
  background: url("./assets/images/activity_before.png") no-repeat top left;
  width: 599px;
  height: 1276px;
  position: absolute;
  top: -200px;
  left: 0;
  z-index: -1;
}

.activity_section::after {
  content: "";
  background: url("./assets/images/activity_after.png") no-repeat bottom right;
  width: 409px;
  height: 934px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.faq_section {
  padding: 100px 0;
}

.faq_section::before {
  content: "";
  background: url("./assets/images/faq_before.png") no-repeat top center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: -1;
}

.custom_accordian .accordion-item {
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(17, 17, 17, 0.8) !important;
}

.custom_accordian .accordion-button {
  background: transparent;
  border: none;
  box-shadow: none;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.8px;
}

.custom_accordian .accordion-body p.para {
  color: #fff;
}

.custom_accordian .accordion-button:not(.collapsed) {
  color: #0056ff;
}

.custom_accordian .accordion-button::after {
  background: url("./assets/images/accordian_btn.png") no-repeat;
  width: 40px;
  height: 39px;
}

.custom_accordian .accordion-button:not(.collapsed)::after {
  background: url("./assets/images/accordian_btn.png") no-repeat;
  transform: rotate(90deg);
}

.footer_about p {
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 0;
  max-width: 70%;
}

.footer_about img {
  width: 220px;
  margin-bottom: 30px;
}

.benefits_section::before {
  content: "";
  background: url("./assets/images/bg_02.png") no-repeat top;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.benefits_list {
  padding: 0;
  margin: 0;
}

.benefits_list li {
  list-style: none;
  position: relative;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 40px;
  padding-left: 50px;
}

.benefits_list li::after {
  content: "";
  background: url("./assets/images/list_icon.png") no-repeat;
  width: 33px;
  height: 33px;
  position: absolute;
  top: 0;
  left: 0;
}

.footer {
  background: rgb(34, 0, 1);
  background: linear-gradient(
    180deg,
    rgba(34, 0, 1, 1) 0%,
    rgba(0, 5, 23, 1) 100%
  );
  mix-blend-mode: plus-lighter;
  padding: 30px 0;
}

.footer_panel_top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 15px;
  padding-bottom: 20px;
}

.footer ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.footer_social_links {
  display: flex;
  gap: 20px;
}

ul.footer_social_links li a {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  width: 36px;
  height: 36px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
  text-decoration: none;
}

ul.footer_social_links li a:hover {
  background: linear-gradient(
    167deg,
    rgba(255, 0, 8, 1) 0%,
    rgba(0, 86, 255, 1) 100%
  );
}

.footer_panel_bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.footer_panel_bottom p {
  font-size: 15px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 0;
}

ul.footer_menu_links {
  display: flex;
  gap: 30px;
}

ul.footer_menu_links li {
  position: relative;
}

ul.footer_menu_links li::after {
  content: "";
  background: #fff;
  width: 1px;
  height: 10px;
  position: absolute;
  top: 10px;
  right: -15px;
}

ul.footer_menu_links li:last-child:after {
  display: none;
}

ul.footer_menu_links li a {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  text-decoration: none;
}

.stats_panel_wrapper {
  background: linear-gradient(180deg, #000 50%, #ff0008 375.49%);
  padding: 15px 10px;
  z-index: 2;
  position: relative;
}

.stats_panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}

.stats_panel div {
  display: flex;
  align-items: center;
  gap: 30px;
}

.stats_panel div label {
  white-space: nowrap;
}

.stats_panel div label:nth-child(1) {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.64px;
}

.stats_panel div label:nth-child(2) {
  color: #0056ff;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.72px;
}

.primary_modal .modal-content {
  background: url("./assets/images/body_bg.png") repeat top;
}

.primary_modal .modal-header {
  border-bottom: 1px solid #1d1d1d;
}

.primary_modal .modal-header .btn-close {
  filter: invert(1);
  box-shadow: none !important;
}

.connect_wallet_button {
  text-align: center;
  border: 1px solid #171717;
  border-radius: 10px;
  padding: 15px;
  cursor: pointer;
  margin-bottom: 15px;
  transition: 0.2s all;
}

.connect_wallet_button:hover {
  background: linear-gradient(
    167deg,
    rgba(255, 0, 8, 1) 0%,
    rgba(0, 86, 255, 1) 100%
  );
}

.connect_wallet_button img {
  width: 42px;
  margin-bottom: 10px;
}

.connect_wallet_button h4 {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 0;
}

.connect_wallet_button p {
  margin-bottom: 0;
  color: #c5c5c5;
}

/* Dashboard Css */
.inner_wrapper {
  min-height: 100vh;
  padding-top: 50px;
  padding-bottom: 100px;
  position: relative;
  z-index: 1;
}

.inner_wrapper::before {
  content: "";
  background: url("./assets/images/activity_before.png") no-repeat left top;
  width: 100%;
  height: 1276px;
  position: absolute;
  z-index: -1;
  top: -120px;
}

.inner_wrapper::after {
  content: "";
  background: url("./assets/images/activity_after.png") no-repeat right bottom;
  width: 100%;
  height: 934px;
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 0;
}

.inner_head_title {
  font-size: 40px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.6px;
  margin-bottom: 100px;
}

.user_info_panel {
  display: flex;
  align-items: center;
  gap: 24px;
}

.inp_grp {
  display: flex;
  align-items: center;
  position: relative;
}

ul.share_list {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  gap: 20px;
  background: rgba(255, 0, 8, 0.1);
  border-radius: 2.5px;
  padding: 8px 12px;
  margin-top: 5px;
}

.affiliate_icon {
  cursor: pointer;
}

.affiliate_icon:hover path,
ul.share_list li a:hover svg path {
  stroke: #0056ff;
}

.affiliate_inp {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  background: transparent !important;
  border: none;
  box-shadow: none !important;
  padding-left: 0;
  user-select: none;
  pointer-events: none;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user_info {
  width: 100%;
}

.user_info h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}

.user_info h5 {
  color: #9f9f9f;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.user_info h5 span {
  color: #fff;
}

.user_earnings_panel {
  padding: 2px;
  border-radius: 5px;
  background: linear-gradient(
    90deg,
    rgba(255, 0, 8, 1) 0%,
    rgba(0, 86, 255, 1) 100%
  );
  text-align: center;
  height: 100%;
  transition: 0.2s all;
  cursor: default;
}

.user_earnings_panel > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 20px;
  border-radius: 5px;
  background: url("./assets/images/body_bg.png") no-repeat;
}

.user_earnings_info {
  border-radius: 2.5px;
  background: rgb(37 37 37 / 25%);
  padding: 20px;
  mix-blend-mode: plus-lighter;
  text-align: center;
}

.user_earnings_info h4 {
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.user_earnings_info h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}

.user_earnings_info h3 span {
  color: #9f9f9f;
}

.user_earnings_info h3 small {
  color: #9f9f9f;
  font-size: 14px;
  font-weight: 500;
}

.dashboard_accordian {
  padding-left: 80px;
  margin-top: 50px;
}

.dash_height_row {
  position: relative;
  z-index: 1;
}

.dash_height_row::before {
  content: "";
  border-left: 1.5px dashed #1e1e1e;
  width: 1.5px;
  height: calc(100% - 320px);
  position: absolute;
  left: 54px;
  top: 350px;
  z-index: -1;
}

.dash_height_row::after {
  content: "";
  background: #1e1e1e;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: -32px;
  left: 49px;
  border-radius: 100%;
}

.dashboard_accordian .accordion-item {
  background: transparent;
  border: none;
  position: relative;
  margin-bottom: 100px;
}

.dashboard_accordian .accordion-item:last-child {
  margin-bottom: 0;
}

.dashboard_accordian .collapse.show::before {
  content: "";
  border-left: 1.5px dashed #1e1e1e;
  width: 1.5px;
  height: 100%;
  position: absolute;
  left: -4px;
  top: 30px;
  z-index: -1;
}

.dashboard_accordian .collapse.show::after {
  content: "";
  background: #1e1e1e;
  width: 9px;
  height: 9px;
  position: absolute;
  bottom: -30px;
  left: -8px;
  border-radius: 100%;
}

.dashboard_accordian .accordion-button {
  background: transparent;
  border: none;
  box-shadow: none;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  gap: 10px;
}

.dashboard_accordian .accordion-button:not(.collapsed) {
  color: #0056ff;
}

.dashboard_accordian .accordion-button::after {
  position: absolute;
  left: -15px;
  background: url("./assets/images/plus_icon.png") no-repeat;
  width: 23px;
  height: 23px;
}

.accordion-button:not(.collapsed)::after {
  background: url("./assets/images/minus_icon.png") no-repeat;
}

.dash_top_box {
  border-radius: 10px;
  background: rgba(37, 37, 37, 0.2);
  background-blend-mode: normal;
  padding: 20px;
  z-index: 10;
  position: relative;
}

.dash_black_box {
  padding: 2px;
  border-radius: 5px;
  /* background: #000 url("./assets/images/body_bg.png") no-repeat; */
  background: linear-gradient(
    90deg,
    rgba(255, 0, 8, 1) 0%,
    rgba(0, 86, 255, 1) 100%
  );
  text-align: center;
  height: 100%;
  transition: 0.2s all;
  cursor: default;
  min-height: 112px;
}

.dash_black_box div {
  background: url("./assets/images/body_bg.png") no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 3px;
}

.dash_black_box:hover {
  background: linear-gradient(
    90deg,
    rgba(255, 0, 8, 1) 0%,
    rgba(0, 86, 255, 1) 100%
  );
}

.dash_black_box:hover div {
  background: #000;
}

.dash_black_box h4 {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.dash_black_box h5 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}

.dash_black_box h5 span {
  color: #9f9f9f;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.affiliate_link_box {
  background: linear-gradient(180deg, #000517 0%, #220001 100%);
  padding: 20px;
  border-radius: 10px;
}

.packages_panel {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.packages_panel .progress {
  width: 100%;
  height: 10px;
  background: #000517;
}

.packages_div {
  padding: 2px;
  border-radius: 10px;
  background: #000;
  mix-blend-mode: plus-lighter;
  transition: 0.2s all;
  cursor: default;
  position: relative;
}

.packages_div_inner {
  border-radius: 9px;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.package_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.package_details h3 {
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.package_details h4 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  gap: 10px;
}

.package_details h4 span {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.progress_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
}

.progress_info p {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.progress_info p span {
  color: #919cce;
}

.packages_div .primary_btn {
  padding: 2px;
  box-shadow: none;
}

.packages_div .primary_btn span {
  text-align: center;
}

.capping_div {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
}

.capping_div h3 {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}

.capping_div h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  gap: 10px;
}

.capping_div h4 span {
  font-size: 14px;
  font-weight: 500;
}

/* Package 1 */
.packages_div_one .packages_div_inner {
  background: linear-gradient(
    180deg,
    #710003 -5%,
    #001465 54.63%,
    #001465 103.25%
  );
}

.packages_div_one .package_details h4 span,
.packages_div_one .package_details h3 {
  color: #d8a2a3;
}

.packages_div.active_package.packages_div_one {
  background: linear-gradient(
    180deg,
    rgba(255, 0, 8, 1) 0%,
    rgba(0, 86, 255, 1) 100%
  );
}

/* Package 2 */
.packages_div_two .packages_div_inner {
  background: linear-gradient(180deg, #060f00 0%, #000f13 100%);
}

.packages_div_two .primary_btn {
  background: linear-gradient(90deg, #70ff00 0%, #70ff00 100%);
}

.packages_div_two .primary_btn:hover span {
  color: #000;
}

.packages_div_two .package_details h4 span,
.packages_div_two .package_details h3,
.packages_div_two .capping_div h4 span {
  color: #94bc79;
}

.packages_div_two .capping_div {
  border: 1px solid rgba(148, 188, 121, 0.2);
}

.packages_div_two .capping_div h4 {
  color: #68f911;
}

.packages_div.active_package.packages_div_two {
  background: linear-gradient(180deg, #61ec04 0%, #70ff00 100%);
}

/* Package 3 */
.packages_div_three .packages_div_inner {
  background: linear-gradient(180deg, #160020 0%, #0e0700 96.44%);
}

.packages_div_three .primary_btn {
  background: linear-gradient(90deg, #ad00ff 0%, #ad00ff 100%);
}

.packages_div_three .primary_btn:hover span {
  color: #fff;
}

.packages_div_three .package_details h4 span,
.packages_div_three .package_details h3,
.packages_div_three .capping_div h4 span {
  color: #a978c0;
}

.packages_div_three .capping_div {
  border: 1px solid rgba(169, 120, 192, 0.2);
}

.packages_div_three .capping_div h4 {
  color: #ad00ff;
}

.packages_div.active_package.packages_div_three {
  background: linear-gradient(180deg, #ad00ff 0%, #ad00ff 100%);
}

/* Package 4 */
.packages_div_four .packages_div_inner {
  background: linear-gradient(180deg, #250d01 0%, #461801 96.44%);
}

.packages_div_four .primary_btn {
  background: linear-gradient(90deg, #ff5500 0%, #ff5500 100%);
}

.packages_div_four .primary_btn:hover span {
  color: #fff;
}

.packages_div_four .package_details h4 span,
.packages_div_four .package_details h3,
.packages_div_four .capping_div h4 span {
  color: #fd9a68;
}

.packages_div_four .capping_div {
  border: 1px solid rgba(169, 120, 192, 0.2);
}

.packages_div_four .capping_div h4 {
  color: #ff5500;
}

.packages_div.active_package.packages_div_four {
  background: linear-gradient(180deg, #ff5500 0%, #ff5500 100%);
}

/* Package 5 */
.packages_div_five .packages_div_inner {
  background: linear-gradient(180deg, #022e1f 0%, #010806 96.44%);
}

.packages_div_five .primary_btn {
  background: linear-gradient(90deg, #0fe89d 0%, #0fe89d 100%);
}

.packages_div_five .primary_btn:hover span {
  color: #000;
}

.packages_div_five .package_details h4 span,
.packages_div_five .package_details h3,
.packages_div_five .capping_div h4 span {
  color: #c6ffeb;
}

.packages_div_five .capping_div {
  border: 1px solid rgb(15 232 157 / 20%);
}

.packages_div_five .capping_div h4 {
  color: #0fe89d;
}

.packages_div.active_package.packages_div_five {
  background: linear-gradient(180deg, #0fe89d 0%, #0fe89d 100%);
}

/* Package 6 */
.packages_div_six .packages_div_inner {
  background: linear-gradient(180deg, #4c3200 0%, #221802 96.44%);
}

.packages_div_six .primary_btn {
  background: linear-gradient(90deg, #ffa800 0%, #ffa800 100%);
}

.packages_div_six .primary_btn:hover span {
  color: #000;
}

.packages_div_six .package_details h4 span,
.packages_div_six .package_details h3,
.packages_div_six .capping_div h4 span {
  color: #ffdd9b;
}

.packages_div_six .capping_div {
  border: 1px solid rgb(255 168 0 / 20%);
}

.packages_div_six .capping_div h4 {
  color: #ffa800;
}

.packages_div.active_package.packages_div_six {
  background: linear-gradient(180deg, #ffa800 0%, #ffa800 100%);
}

/* Package 7 */
.packages_div_seven .packages_div_inner {
  background: linear-gradient(180deg, #00146e 0%, #000b3f 96.44%);
}

.packages_div_seven .primary_btn {
  background: linear-gradient(90deg, #002eff 0%, #002eff 100%);
}

.packages_div_seven .primary_btn:hover span {
  color: #fff;
}

.packages_div_seven .package_details h4 span,
.packages_div_seven .package_details h3,
.packages_div_seven .capping_div h4 span {
  color: #a7b6ff;
}

.packages_div_seven .capping_div {
  border: 1px solid rgb(0 46 255 / 40%);
}

.packages_div_seven .capping_div h4 {
  color: #002eff;
}

.packages_div.active_package.packages_div_seven {
  background: linear-gradient(180deg, #002eff 0%, #002eff 100%);
}

/* Package 8 */
.packages_div_eight .packages_div_inner {
  background: linear-gradient(180deg, #740032 0%, #380017 96.44%);
}

.packages_div_eight .primary_btn {
  background: linear-gradient(90deg, #ff006b 0%, #ff006b 100%);
}

.packages_div_eight .primary_btn:hover span {
  color: #fff;
}

.packages_div_eight .package_details h4 span,
.packages_div_eight .package_details h3,
.packages_div_eight .capping_div h4 span {
  color: #ffa7cc;
}

.packages_div_eight .capping_div {
  border: 1px solid rgb(255 0 107 / 30%);
}

.packages_div_eight .capping_div h4 {
  color: #ff006b;
}

.packages_div.active_package.packages_div_eight {
  background: linear-gradient(180deg, #ff006b 0%, #ff006b 100%);
}

/* Package 9 */
.packages_div_nine .packages_div_inner {
  background: linear-gradient(180deg, #006379 0%, #00323d 96.44%);
}

.packages_div_nine .primary_btn {
  background: linear-gradient(90deg, #00d1ff 0%, #00d1ff 100%);
}

.packages_div_nine .primary_btn:hover span {
  color: #fff;
}

.packages_div_nine .package_details h4 span,
.packages_div_nine .package_details h3,
.packages_div_nine .capping_div h4 span {
  color: #bdf3ff;
}

.packages_div_nine .capping_div {
  border: 1px solid rgb(0 209 255 / 40%);
}

.packages_div_nine .capping_div h4 {
  color: #00d1ff;
}

.packages_div.active_package.packages_div_nine {
  background: linear-gradient(180deg, #00d1ff 0%, #00d1ff 100%);
}

/* Package 10 */
.packages_div_ten .packages_div_inner {
  background: linear-gradient(180deg, #769400 0%, #3a4900 96.44%);
}

.packages_div_ten .primary_btn {
  background: linear-gradient(90deg, #ccff00 0%, #ccff00 100%);
}

.packages_div_ten .primary_btn:hover span {
  color: #000;
}

.packages_div_ten .package_details h4 span,
.packages_div_ten .package_details h3,
.packages_div_ten .capping_div h4 span {
  color: #e7ff86;
}

.packages_div_ten .capping_div {
  border: 1px solid rgb(204 255 0 / 30%);
}

.packages_div_ten .capping_div h4 {
  color: #ccff00;
}

.packages_div.active_package.packages_div_ten {
  background: linear-gradient(180deg, #ccff00 0%, #ccff00 100%);
}

/*  */
.package_diasbled {
  pointer-events: none;
  user-select: none;
  background: transparent;
}

.package_diasbled .packages_div_inner {
  background: rgba(37, 37, 37, 0.2);
}

.packages_div.package_diasbled .primary_btn {
  color: #272727 !important;
  background: #000;
}

.packages_div.package_diasbled .capping_div {
  border: 1px solid #3d3d3d;
}

/* Tree Structure */
.tree {
  width: 100%;
  height: 100%;
}

.tree ul {
  margin: 0;
  padding: 0;
  padding-top: 20px;
  position: relative;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
}

.tree li {
  /* float: left; */
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 30px 20px 0 20px;
  transition: all 0.5s;
}

.tree li::before,
.tree li::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 2px solid #1e1e1e;
  width: 50%;
  height: 30px;
}

.tree li::after {
  right: auto;
  left: 50%;
  border-left: 2px solid #1e1e1e;
}

.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}

.tree li:only-child {
  padding-top: 0;
}

.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}

.tree li:last-child::before {
  border-right: 2px solid #1e1e1e;
  border-radius: 0 2px 0 0;
}

.tree li:first-child::after {
  border-radius: 2px 0 0 0;
}

.tree ul ul::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px solid #1e1e1e;
  width: 0;
  height: 20px;
}

.tree li span {
  border: 2px solid #1e1e1e;
  padding: 5px 10px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.5s;
  width: 64px;
  height: 64px;
  margin: 0 auto;
  z-index: 10;
  position: relative;
}

/* .tree li span:hover,
.tree li span:hover + ul li span {
  background: #c8e4f8;
  color: #000;
  border: 1px solid #1E1E1E;
} */
.tree li span:hover + ul li::after,
.tree li span:hover + ul li::before,
.tree li span:hover + ul::before,
.tree li span:hover + ul ul::before {
  border-color: #1e1e1e;
}

.tree li span.tree_package_one {
  border: 2px solid #0056ff;
}

.tree li span.tree_package_two {
  border: 2px solid #70ff00;
}

.tree li span.tree_package_three {
  border: 2px solid #ad00ff;
}

.tree li span.tree_package_four {
  border: 2px solid #ff5500;
}

.tree li span.tree_package_five {
  border: 2px solid #0fe89d;
}

.tree li span.tree_package_six {
  border: 2px solid #ffa800;
}

.tree li span.tree_package_seven {
  border: 2px solid #0500ff;
}

.tree li span.tree_package_eight {
  border: 2px solid #ff006b;
}

.tree li span.tree_package_nine {
  border: 2px solid #00d1ff;
}

.tree li span.tree_package_ten {
  border: 2px solid #ccff00;
}

.custom_tooltip {
  z-index: 100;
}

table.table.primary_table {
  border: 1px solid #1e1e1e;
}

.primary_table thead th {
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.8px;
  border-radius: 0;
  border: 1px solid #1e1e1e;
  border-left: 0;
  background: #0f0b0b;
  border-right: 0;
  padding: 12px 30px;
}

.primary_table tbody td {
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.8px;
  border-bottom: 1px solid #1e1e1e;
  background: rgba(37, 37, 37, 0.2);
  padding: 12px 30px;
}

.primary_table tbody tr {
  transition: 0.2s all;
}

.primary_table tbody tr:hover {
  background: linear-gradient(90deg, #000517 0%, #220001 100%);

  background-blend-mode: plus-lighter, normal;
}

.flex_maintitle {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 100px;
}

.flex_maintitle .inner_head_title {
  margin-bottom: 0;
}

.dash_black_box_grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.dash_height_row_inner.dash_height_row::before {
  top: 200px;
  height: calc(100% - 180px);
}

.dash_top_box_package_one {
  background: linear-gradient(
    180deg,
    #710003 -5%,
    #001465 54.63%,
    #001465 103.25%
  );
  border: 1px solid #ff0008;
}

.dash_top_box_package_two {
  background: linear-gradient(180deg, #060f00 0%, #000f13 100%);
  border: 1px solid #68f911;
}

.dash_top_box_package_three {
  background: linear-gradient(180deg, #160020 0%, #0e0700 96.44%);
  border: 1px solid #ad00ff;
}

.dash_top_box_package_four {
  background: linear-gradient(180deg, #250d01 0%, #461801 96.44%);
  border: 1px solid #ff5500;
}

.dash_top_box_package_five {
  background: linear-gradient(180deg, #022e1f 0%, #010806 96.44%);
  border: 1px solid #0fe89d;
}

.dash_top_box_package_six {
  background: linear-gradient(180deg, #4c3200 0%, #221802 96.44%);
  border: 1px solid #ffa800;
}

.dash_top_box_package_seven {
  background: linear-gradient(180deg, #00146e 0%, #000b3f 96.44%);
  border: 1px solid #002eff;
}

.dash_top_box_package_eight {
  background: linear-gradient(180deg, #740032 0%, #380017 96.44%);
  border: 1px solid #ff006b;
}

.dash_top_box_package_nine {
  background: linear-gradient(180deg, #006379 0%, #00323d 96.44%);
  border: 1px solid #00d1ff;
}

.dash_top_box_package_ten {
  background: linear-gradient(180deg, #769400 0%, #3a4900 96.44%);
  border: 1px solid #ccff00;
}

.tree_search {
  width: 40%;
  margin: 5px auto 15px;
}

.primary_input {
  background: #040404 !important;
  border: 1px solid #1e1e1e !important;
  box-shadow: none !important;
  color: #fff !important;
  padding: 12px 15px;
}

.roadmap {
  background: url("./assets/images/roadmap_bg.png") no-repeat center;
}

.roadmap .box {
  padding: 10px;
}

.roadmap .btn_rightbdr {
  position: relative;
  margin-bottom: 60px;
  display: inline-block;
}

.roadmap .btn_rightbdr::before {
  content: "";
  position: absolute;
  background: #0056ff;
  height: 2px;
  width: 117%;
  left: 100%;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.roadmap .box .butn {
  border-radius: 100px;
  border: 2px solid #0056ff;
  padding: 15px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  display: inline-flex;
  align-items: center;
  gap: 15px;
  position: relative;
  width: 200px;
}

.roadmap .box .butn::before {
  content: "";
  position: absolute;
  background: #0056ff;
  width: 2px;
  height: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 100%;
}

.roadmap .box .butn::after {
  content: "";
  position: absolute;
  background: #0056ff;
  width: 8px;
  height: 8px;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: calc(100% + 0px);
  border-radius: 50%;
  opacity: 0;
}

.roadmap .box .butn .svg_btn {
  background: #0056ff;
  border-radius: 50%;
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: 0.7s ease-out;
}

.roadmap .box .butn svg {
  position: absolute;
}

.roadmap .box .butn svg:nth-child(2) {
  transform: translate(-90px, 90px);
}

.roadmap .slick-current .box .butn {
  background: #0056ff;
  transition: 0.7s ease-out;
}

.roadmap .slick-current .box .butn::before {
  opacity: 1;
  height: 40px;
  transition: 0.7s ease-out;
}

.roadmap .slick-current .box .butn::after {
  opacity: 1;
  transition: 0.7s ease-out;
  top: calc(100% + 40px);
}

.roadmap .slick-current .box .butn .svg_btn {
  background: #fff;
  transition: 0.7s ease-out;
}

.roadmap .slick-current .box .butn svg:nth-child(2) {
  transform: translate(0px, 0px);
  transition: 0.7s ease-out;
}

.roadmap .slick-current .box .butn svg:nth-child(1) {
  transform: translate(90px, -90px);
  transition: 0.7s ease-out;
}

.roadmap .slick-current + .slick-slide + .slick-slide .btn_rightbdr::after,
.roadmap .slick-slide:last-child .btn_rightbdr::after {
  content: "";
  position: absolute;
  left: 112%;
  background: url("./assets/images/arrow.png") no-repeat center;
  height: 15px;
  top: 0;
  bottom: 0.4px;
  margin: auto 0;
  width: 100%;
  background-position: 100% 0%;
}

.roadmap .box h5 {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  /* 166.667% */
  letter-spacing: -0.96px;
}

.roadmap .box p {
  font-weight: 400;
}

.roadmap .slick-prev,
.roadmap .slick-next {
  background: #0e0e0e !important;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  z-index: 1;
  display: flex !important;
  align-items: center;
  justify-content: center;
  top: -90px;
}

.roadmap .slick-next::before,
.roadmap .slick-prev::before {
  content: "";
  width: 29px;
  height: 22px;
  filter: brightness(0) invert(0.3);
}

.roadmap .slick-prev::before {
  background: url("./assets/images/prev_arrow.png") no-repeat;
}

.roadmap .slick-next::before {
  background: url("./assets/images/next_arrow.png") no-repeat;
}

.roadmap .slick-prev:hover::before,
.roadmap .slick-next:hover::before {
  filter: unset;
}

.roadmap .slick-prev {
  left: unset;
  right: 80px;
}

.roadmap .slick-next {
  right: 0%;
}

/* Media Queries */
@media screen and (max-width: 1399px) {
  .roadmap .btn_rightbdr::before {
    width: 87%;
  }

  .roadmap .slick-current + .slick-slide + .slick-slide .btn_rightbdr::after,
  .roadmap .slick-slide:last-child .btn_rightbdr::after {
    left: 81.9%;
  }

  .activity_slider_single {
    padding: 10px;
    margin: 6px;
  }

  .activity_time p {
    font-size: 14px;
  }

  .dash_height_row::before {
    top: 375px;
    height: calc(100% - 350px);
  }
}

@media screen and (max-width: 1199px) {
  .roadmap {
    background-size: 100% 100%;
  }

  .roadmap .btn_rightbdr::before {
    width: 56%;
  }

  .roadmap .slick-current + .slick-slide + .slick-slide .btn_rightbdr::after,
  .roadmap .slick-slide:last-child .btn_rightbdr::after {
    left: 51.9%;
  }

  .activity_slider_single {
    margin: 10px;
  }

  .custom_slider .slick-prev {
    left: 42%;
  }

  .custom_slider .slick-next {
    right: 42%;
  }

  .main_title {
    font-size: 82px;
    margin-bottom: 30px;
  }

  .community_section_inner .nav-fill .nav-item .nav-link {
    font-size: 30px;
  }

  .community_section_inner .nav-fill .nav-item .nav-link svg {
    width: 50px;
    height: 50px;
  }

  .user_earnings_panel > div {
    display: block;
  }

  .packages_panel {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .tree li {
    padding: 30px 10px 0 10px;
  }

  .tree li span {
    width: 56px;
    height: 56px;
  }

  .dash_black_box_grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media screen and (max-width: 991px) {
  .roadmap .btn_rightbdr::before {
    width: 75%;
  }

  .roadmap .slick-current + .slick-slide .btn_rightbdr::after,
  .roadmap .slick-slide:last-child .btn_rightbdr::after {
    content: "";
    position: absolute;
    left: 69.9%;
    background: url("./assets/images/arrow.png") no-repeat center;
    height: 15px;
    top: 0;
    bottom: 0.4px;
    margin: auto 0;
    width: 100%;
    background-position: 100% 0%;
  }

  .main_navbar .offcanvas {
    /* background: linear-gradient(180deg, #000 50%, #FF0008 375.49%); */
    background: url("./assets/images/body_bg.png") repeat top;
  }

  .offcanvas-header .btn-close {
    filter: invert(1);
    box-shadow: none !important;
  }

  .activity_details p {
    font-size: 14px;
  }

  .activity_details h5 {
    font-size: 18px;
  }

  .activity_details img {
    width: 50px;
  }

  .custom_slider .slick-next {
    right: 38%;
  }

  .custom_slider .slick-prev {
    left: 38%;
  }

  .main_title {
    font-size: 62px;
    line-height: 66px;
  }

  .community_section_inner .nav-fill .nav-item .nav-link {
    font-size: 20px;
  }

  .button__icon-wrapper {
    width: 36px;
    height: 36px;
  }

  .community_section_inner .nav-fill .nav-item .nav-link svg {
    width: 15px;
    height: 15px;
  }

  .community_section_inner .nav-pills {
    position: relative;
    bottom: unset;
  }

  .community_section_inner .tab-content {
    max-width: 100%;
    margin: 30px 0;
  }

  .community_section {
    padding: 100px 0;
  }

  .community_section::after {
    background-size: 80%;
    background-repeat: no-repeat;
    top: 0;
  }

  .stats_panel_wrapper .container {
    max-width: 98%;
  }

  .stats_panel {
    overflow-x: auto;
    padding-bottom: 10px;
  }

  .stats_panel div {
    gap: 15px;
  }

  .community_section {
    text-align: center;
  }

  .navbar-toggler {
    box-shadow: none !important;
  }

  .activity_section .small_main_title {
    margin-top: 0;
  }

  .benefits_list {
    margin-bottom: 30px;
  }

  .dash_top_box [class*="col-"] {
    margin-bottom: 24px;
  }

  .navbar_right {
    flex-direction: column;
    width: 100%;
  }

  .navbar_right .primary_btn,
  .navbar_right .dropdown,
  .main_navbar .primary_btn span {
    width: 100% !important;
  }

  .dropdown-menu {
    background: rgb(37 37 37);
    mix-blend-mode: unset;
  }

  .dash_height_row::before,
  .dash_height_row::after {
    /* top: 748px;
    height: calc(100% - 720px); */
    display: none;
  }

  .dashboard_accordian {
    padding-left: 15px;
    margin-top: 30px;
  }

  .dashboard_accordian .accordion-item {
    margin-bottom: 50px;
  }

  .dash_black_box_grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .tree_search {
    width: 60%;
  }
}

@media screen and (max-width: 767px) {
  .custom_slider .slick-prev,
  .custom_slider .slick-next {
    bottom: -90px;
  }
  .custom_slider .slick-prev,
  .custom_slider .slick-next {
    width: 40px;
    height: 40px;
  }
  .custom_slider .slick-next::before,
  .custom_slider .slick-prev::before {
    width: 19px;
    background-size: contain !important;
    background-position: center !important;
  }

  .roadmap .slick-prev {
    right: 60px;
  }
  .roadmap .slick-prev,
  .roadmap .slick-next {
    width: 40px;
    height: 40px;
  }
  .roadmap .slick-next::before,
  .roadmap .slick-prev::before {
    width: 19px;
    background-size: contain !important;
    background-position: center !important;
  }
  .roadmap .slick-current + .slick-slide .btn_rightbdr::after,
  .roadmap .slick-slide:last-child .btn_rightbdr::after {
    left: 24.9%;
    bottom: 1px;
  }

  .roadmap .box .butn {
    font-size: 18px;
    padding: 10px 15px;
  }

  .roadmap .btn_rightbdr::before {
    width: 29%;
  }
  .page_header {
    background-size: 100%;
  }
  .page_header::before {
    opacity: 0.5;
  }
  .banner_content img {
    width: 250px;
  }
  .footer_panel_top,
  .footer_panel_bottom {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer_about p {
    max-width: 100%;
    margin-bottom: 15px;
  }

  .about_section::after,
  .about_section::before {
    width: 100%;
  }

  .community_section_inner .nav-pills {
    justify-content: flex-start;
    gap: 15px;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 15px;
  }

  .community_section_inner .nav-fill .nav-item .nav-link {
    font-size: 18px;
    white-space: nowrap;
  }

  .activity_slider_single {
    padding: 15px;
  }

  .custom_slider .slick-next {
    right: 35%;
  }

  .custom_slider .slick-prev {
    left: 35%;
  }

  .community_section::after,
  .activity_section::before,
  .activity_section::after,
  .page_header::before {
    width: 100%;
  }

  .para,
  .benefits_list li {
    font-size: 16px;
    line-height: 28px;
  }

  .custom_accordian .accordion-button {
    font-size: 15px;
    letter-spacing: -0.1px;
  }

  .benefits_list li {
    margin-top: 20px;
  }

  .packages_panel {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .dashboard_accordian .accordion-button,
  .user_earnings_info h3 {
    font-size: 18px;
  }

  .inner_head_title {
    font-size: 20px;
    margin-bottom: 30px;
    letter-spacing: -0.5px;
  }

  .user_info h4 {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .user_info h5 {
    font-size: 14px;
  }

  .dash_black_box_grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .flex_maintitle {
    gap: 15px;
    margin-bottom: 30px;
  }

  .packages_div .primary_btn,
  .package_details h3,
  .user_earnings_info h4,
  .affiliate_inp {
    font-size: 14px;
  }

  .package_details h4,
  .capping_div h4 {
    font-size: 16px;
    gap: 5px;
  }

  .package_details h4 span {
    font-size: 12px;
  }

  .mobile_hide {
    display: none !important;
  }

  .primary_table thead th,
  .primary_table tbody td {
    font-size: 14px;
    white-space: nowrap;
    padding: 10px 10px;
  }

  .user_info_panel {
    gap: 10px;
  }

  .user_info_panel img {
    width: 50px;
  }

  .dash_black_box h5 {
    font-size: 20px;
  }

  .dash_black_box h4 {
    font-size: 16px;
  }

  .capping_div {
    padding: 8px;
  }

  .flex_maintitle img {
    width: 40px;
  }

  .tree_search {
    width: 70%;
  }

  .banner_content h5 {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.3px;
    max-width: 85%;
  }
}

@media screen and (max-width: 575px) {
  .para {
    font-size: 14px !important;
    line-height: 20px;
  }

  .para,
  .benefits_list li {
    font-size: 14px;
  }
  .roadmap .box {
    text-align: center;
  }
  .roadmap .box .butn {
    font-size: 14px;
    padding: 6px 7px;
    gap: 6px;
    line-height: normal;
    width: 130px;
  }

  .roadmap .box .butn .svg_btn {
    width: 20px;
    height: 20px;
  }

  .roadmap .box .butn svg {
    width: 10px;
  }

  .roadmap .box h5 {
    font-size: 16px;
    line-height: unset;
  }

  .roadmap .slick-prev,
  .roadmap .slick-next {
    top: -60px;
  }
  .roadmap .btn_rightbdr::before {
    display: none;
  }
  .roadmap .slick-current + .slick-slide .btn_rightbdr::after,
  .roadmap .slick-slide:last-child .btn_rightbdr::after {
    display: none;
  }
  .community_section_inner .nav-fill .nav-item .nav-link {
    font-size: 16px;
    padding: 5px 12px 5px 7px;
  }
  .small_main_title {
    font-size: 18px;
    letter-spacing: -0.5px;
  }
  .benefits_list li::after {
    top: 3px;
  }

  .main_title {
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -1px;
  }

  .dash_black_box_grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .progress_info {
    flex-direction: column;
  }

  .progress_info p {
    margin-bottom: 8px;
  }

  .tree_search {
    width: 95%;
  }
}

@media screen and (max-width: 440px) {
  .activity_slider_single {
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
  }

  .activity_details {
    width: 100%;
    justify-content: center;
  }

  .activity_time {
    width: 100%;
    justify-content: center;
    margin-top: 5px;
  }

  .custom_slider .slick-next {
    right: 35%;
  }
  .custom_slider .slick-prev {
    left: 35%;
  }

  .tree {
    transform: scale(0.7);
  }
}

.loadBefore::before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgb(10 10 10 / 75%);
  z-index: 1500;
  overflow: hidden;
}

input.primary-input {
  background: #000000;
  width: 90%;
  margin: 28px auto;
  padding: 10px;
  border-radius: 9px;
  border: 2px solid #2b1c55;
  color: #ffffff;
}

i.fa.fa-spinner.fa-spin.checkspin {
  float: right;
  margin-top: -38px;
  margin-right: 10px;
  color: #002eff;
}

::placeholder {
  color: #f9f9f9 !important;
}
